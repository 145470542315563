const countries = [
  
  {
    url: 'en-US',
    title: 'USA English',
    country: ['US'],
    language: ['en-US'],
    img: '/images/global/flags/US.png',
    alt: 'USA flag linking to our US English website',
    cookieID: '920e5a74-7399-43be-be36-e661cd63ca12',
    logo: 'combined'
  },
  {
    url: 'es-US',
    title: 'USA Español',
    country: ['US'],
    language: ['eS-US'],
    img: '/images/global/flags/US.png',
    alt: 'USA flag linking to our US Spanish website',
    cookieID: '920e5a74-7399-43be-be36-e661cd63ca12',
    logo: 'combined'
  },
  {
    url: 'en-GB',
    title: 'UK English',
    country: ['GB'],
    language: ['en-GB'],
    img: 'images/global/flags/UK.png',
    alt: 'UK flag linking to our UK English website',
    cookieID: 'a8856cb6-8d17-44e8-8863-9de8849fffec',
    logo: 'combined'
  }  
];

export default countries;
